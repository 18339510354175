<template>
  <div>
    <lms-card>
      <lms-card-title>
        <template #icon>
          <v-icon>mdi-text-box</v-icon>
        </template>

        {{ $tt.capitalize($t('settings.names.functions')) }}
      </lms-card-title>

      <lms-card-item clickable @click="certGen">
        {{ $tt.capitalize($t('settings.names.customCertificate')) }}

        <template #actions>
          <v-icon>mdi-menu-right</v-icon>
        </template>
      </lms-card-item>

      <lms-card-item clickable @click="globalLabel">
        {{ $tt.capitalize($t('settings.names.addGlobalLabel')) }}
      </lms-card-item>

      <lms-card-item clickable @click="initSettings">
        {{ $tt.capitalize($t('settings.names.initGlobalDef')) }}
      </lms-card-item>

      <lms-card-item clickable @click="clearDBCache">
        {{ $tt.capitalize($t('settings.names.clearDBCache')) }}
      </lms-card-item>

      <lms-card-item clickable @click="fixInvalidDate">
        {{ $tt.capitalize($t('settings.names.fixInvalidDate')) }}
      </lms-card-item>

      <lms-card-item clickable @click="checkUsers">
        {{ $tt.capitalize($t('settings.names.checkUsers')) }}
      </lms-card-item>

      <lms-card-item clickable @click="editTsm">
        {{ $tt.capitalize($t('settings.names.tsmManage')) }}

        <template #actions>
          <v-icon>mdi-menu-right</v-icon>
        </template>
      </lms-card-item>
    </lms-card>
  </div>
</template>

<script lang="js">
export default {
  methods: {
    certGen(){
      this.$router.push({ name: 'certificate-generator' });
    },
    async initSettings(){
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.settings.initSettings.title'),
        content: this.$t('alerts.settings.initSettings.content')
      });
      if( response ) {
        await this.$store.dispatch('setting/init');

        this.$store.dispatch('dialog/show', {
          title: this.$t('alerts.settings.initSettings.success.title'),
          content: this.$t('alerts.settings.initSettings.success.content'),
          alert: true,
          accept: 'Ok'
        });
      }
    },
    async globalLabel(){
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.settings.globalLabel.title'),
        content: this.$t('alerts.settings.globalLabel.content')
      });
      if(response){
        await this.$store.dispatch('setting/labelSellerOnEvething');

        this.$store.dispatch('dialog/show', {
          title: this.$t('alerts.settings.globalLabel.success.title'),
          content: this.$t('alerts.settings.globalLabel.success.content'),
          alert: true,
          accept: 'Ok'
        });
      }
    },
    async clearDBCache(){
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.settings.clearDBCache.title'),
        content: this.$t('alerts.settings.clearDBCache.content')
      });
      if(response){
        await this.$store.dispatch('setting/clearDBCache');

        this.$store.dispatch('dialog/show', {
          title: this.$t('alerts.settings.clearDBCache.success.title'),
          content: this.$t('alerts.settings.clearDBCache.success.content'),
          alert: true,
          accept: 'Ok'
        });
      }
    },
    async fixInvalidDate() {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.settings.fixInvalidDate.title'),
        content: this.$t('alerts.settings.fixInvalidDate.content')
      });
      if (response) {
        await this.$store.dispatch('setting/fixInvalidDate')

        this.$store.dispatch('dialog/show', {
          title: this.$t('alerts.settings.fixInvalidDate.success.title'),
          content: this.$t('alerts.settings.fixInvalidDate.success.content'),
          alert: true,
          accept: 'Ok'
        });
      }
    },
    checkUsers() {
      this.$router.push({ name: 'settings-users-check' })
    },
    editTsm() {
      this.$router.push({ name: 'settings-tsm' })
    }
  }
}
</script>
